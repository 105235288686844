import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const toUSDateFormat = (isoDate, fullYear = false) => {
  const date = dayjs(isoDate, "YYYY-MM-DD");

  if (!date.isValid()) return "";

  return date.format(fullYear ? "MM/DD/YYYY" : "MM/DD/YY");
};

export default toUSDateFormat;
