import { useField } from "formik";
import ZipField from "components/ZipField";

const FormikZipField = ({ name, ...props }) => {
  const [{ value, onChange, onBlur }, { touched, error }] = useField(name);

  return (
    <ZipField
      error={touched && error}
      inputProps={{ onBlur }}
      name={name}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
};

export default FormikZipField;
