import { useField } from "formik";
import TextField from "components/TextField";

const FormikTextField = ({ name, inputProps = {}, ...props }) => {
  const [{ value, onChange, onBlur }, { touched, error }] = useField(name);

  return (
    <TextField
      error={touched && error}
      inputProps={{ ...inputProps, onBlur }}
      name={name}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
};

export default FormikTextField;
