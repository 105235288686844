import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const FormSection = ({ children, title, subtitle, ...props }) => {
  const theme = useTheme();

  return (
    <Box {...props}>
      {title && (
        <Box pb={subtitle ? 1 : 3}>
          <Typography component="h2" variant="h6">
            {title}
          </Typography>
        </Box>
      )}
      {subtitle && (
        <Box pb={3}>
          <Typography style={{ color: theme.palette.text.tertiary }} variant="body2">
            {subtitle}
          </Typography>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default FormSection;
