import { useEffect, useState } from "react";
import Loader from "components/Loader";
import Box from "@mui/material/Box";
import Modal from "components/Modal";
import Typography from "@mui/material/Typography";
import translate from "./translate";
import styles from "./styles.module.scss";

const ProcessingModal = ({ open, processingMessage, processingTitle, takingTooLongMessage, takingTooLongTitle }) => (
  <Modal
    classes={{
      root: styles.modal,
      paper: styles.modalContent,
    }}
    fullWidth
    maxWidth="xs"
    open={open}
    shrink
  >
    <Content
      processingMessage={processingMessage}
      processingTitle={processingTitle}
      takingTooLongMessage={takingTooLongMessage}
      takingTooLongTitle={takingTooLongTitle}
    />
  </Modal>
);

export default ProcessingModal;

const Content = ({ processingMessage, processingTitle, takingTooLongMessage, takingTooLongTitle }) => {
  const takingTooLong = useTakingTooLong();

  let title;
  if (takingTooLong) {
    title = takingTooLongTitle || translate("taking_too_long_title");
  } else {
    title = processingTitle || translate("processing_title");
  }

  const description = takingTooLong ? takingTooLongMessage : processingMessage;

  return (
    <Box px={2} py={5} textAlign="center">
      <Box pb={2}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box pb={4}>
        <Typography>{description}</Typography>
      </Box>
      <Loader />
    </Box>
  );
};

export const MILLISECONDS_TAKING_TOO_LONG = 4000;

const useTakingTooLong = () => {
  const [takingTooLong, setTakingTooLong] = useState(false);

  useEffect(() => {
    const timeoutID = setTimeout(() => setTakingTooLong(true), MILLISECONDS_TAKING_TOO_LONG);

    return () => clearTimeout(timeoutID);
  }, []);

  return takingTooLong;
};
