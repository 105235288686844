import Box from "@mui/material/Box";
import formatCurrency from "utils/formatCurrency";
import initTranslationsHelper from "utils/initTranslationsHelper";
import { Typography } from "@mui/material";

const translate = initTranslationsHelper("universal.balance");

const Balance = ({ value }) => {
  const formattedAmount = formatCurrency(value);

  return (
    <Box sx={{ lineHeight: "normal", textAlign: "center" }}>
      <Typography
        sx={{
          color: "var(--palette-text-primary)",
          fontSize: "calc(var(--base-font-size) * 2)",
        }}
      >
        {formattedAmount}
      </Typography>
      <Typography
        sx={{
          color: "var(--palette-text-secondary)",
          fontSize: "calc(var(--base-font-size) * 0.8)",
          lineHeight: "calc(var(--base-font-size) * 1.2)",
          opacity: 0.5,
          textTransform: "uppercase",
        }}
      >
        {translate("owed")}
      </Typography>
    </Box>
  );
};

export default Balance;
