import { useField } from "formik";
import Select from "components/Select";

const FormikSelect = ({ name, onChange, valueOnInitialInteraction, controlledError, ...props }) => {
  const [{ value, onChange: formikOnChange, onBlur }, { touched, error }, { setValue }] = useField(name);

  const handleDefaultValue = () => {
    if (!valueOnInitialInteraction || !!value) return null;
    setValue(valueOnInitialInteraction);
  };

  const handleChange = (event) => {
    formikOnChange(event);
    if (onChange) onChange(event);
  };

  return (
    <Select
      error={touched && (error || controlledError)}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      onMouseDown={!props.disabled && handleDefaultValue}
      onTouchEnd={handleDefaultValue}
      value={value}
      {...props}
    />
  );
};

export default FormikSelect;
