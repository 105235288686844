/**
***************************************************************
***************************************************************
** IF YOU MODIFY THE CONTENT OF THIS DOCUMENT, PLEASE UPDATE **
** THE VERSION NUMBER FOR `practice_patient_terms_of_use` in **
** app/models/agreement.rb **
***************************************************************
***************************************************************
*/
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const TermsOfUsePractice = ({
  termsOfUseVersion,
  goToPageTwo,
  practiceTermsStandaloneUrl,
  forCheckIn,
}) => (
  <>
    {
      practiceTermsStandaloneUrl && (
        <p>
          This agreement can also be viewed, printed, and downloaded at:
          {" "}
          {
            forCheckIn
            ? practiceTermsStandaloneUrl
            : (
              <Link href={practiceTermsStandaloneUrl} rel="noreferrer" target="_blank">
                {practiceTermsStandaloneUrl}
              </Link>
            )
          }
        </p>
      )
    }
    <Box component="header" mb={4} mt={2}>
      <Typography component="h1" variant="h5">TERMS OF USE</Typography>
      <Typography component="h2" variant="h5">{termsOfUseVersion}</Typography>
    </Box>
    <p>
      <b>
        <i>
          <span>
            This is a legally binding agreement. Please read these terms and
            conditions carefully. By using this check-in platform, you
            acknowledge your agreement to be bound by the terms and conditions
            of this agreement. If you do not agree to these terms and
            conditions, you may not use this check-in platform; instead, you
            will need to check in for your appointment in the office at the
            reception desk.
          </span>
        </i>
      </b>
    </p>

    <p>
      <span>
        This agreement states the terms and conditions for your use of the
        software application made available through this tablet or website (the
        “
        <b>Platform</b>
        ”)
        provided to you by the health care provider group with which you have an
        appointment for which you are checking in or with which you are seeking
        a telehealth session (“
        <b>Practice</b>
        ”). Please read
        these terms carefully.
      </span>
    </p>

    <p>
      <span>
        The Platform is not intended to be a substitute for professional medical
        advice, diagnosis, or treatment, and the information in the Platform may
        not be complete or accurate. Always seek the advice of your physician or
        other qualified health care provider with any questions you may have
        regarding a health condition. Never disregard professional medical
        advice or delay in seeking it because of something you have seen in the
        Platform.
        <b>
          <u>
            If you think you may have a medical emergency, dial 911, or, if you
            are in the Practice, see the reception desk immediately.
          </u>
        </b>
      </span>
    </p>

    <p>
      <span>
        You represent that the information indicated in this check-in process
        (the “
        <b>Registration</b>
        ”) is true and complete. The Registration information is incorporated in
        and made a part of this agreement.
      </span>
    </p>

    <p>
      <span>
        Practice hereby grants to you a personal, non-exclusive,
        non-transferable, non-sublicensable, revocable license to use the
        Platform only as specifically set forth in this agreement. Practice
        reserves for itself and its licensors any rights not expressly granted
        in this agreement.
      </span>
    </p>

    <p>
      <span>
        You acknowledge that the Platform is provided to you free of charge and
        Practice may terminate this agreement and the license granted in this
        agreement at any time with or without notice to you by disabling your
        access to the Platform. You may terminate this agreement at any time
        upon written notice to Practice.
      </span>
    </p>

    <p>
      <span>
        You acknowledge and agree that you do not acquire any ownership rights
        to the Platform through this agreement or by your use of the Platform.
        Practice and/or its licensors have and retain exclusive, valid, and
        non-contestable ownership of the Platform and all intellectual property
        and proprietary rights therein. You acknowledge that you may suggest
        improvements and/or communicate to Practice ideas, inventions,
        discoveries, or concepts (“
        <b>Ideas</b>
        ”) related to the Platform and that you and Practice may discover or
        create the Ideas jointly, and you agree that any such Idea shall be and
        remain solely the property of Practice and/or its licensors and may be
        used and sold, licensed, or otherwise provided by Practice and/or its
        licensors to third parties, or published or otherwise publicly
        disclosed, in Practice’s and/or its licensors’ sole discretion without
        notice, attribution, payment of royalties, or liability to you. You
        hereby assign to Practice any and all of your right, title, and interest
        in and to any such Ideas.
      </span>
    </p>

    <p>
      <span>
        In order for Practice to provide the Platform, you grant to Practice a
        non-exclusive right and license to use, copy, distribute, display, and
        transmit information you enter into the Platform, and that the Platform
        obtains about you from Practice’s electronic records system, solely to
        the extent necessary for providing the Platform services. The Platform
        maintains certain personally-identifiable information about you and your
        health, and it facilitates communication between you and your health
        care provider. Except as otherwise required by law or court order or as
        described in
        {" "}
        {
          goToPageTwo
          ? (
            <Link
              className="agreementButton"
              component="button"
              onClick={() => goToPageTwo("noticeOfPrivacyPractices")}
              style={forCheckIn ? { color: "var(--primary-color)" } : null}
              type="button"
              variant="body2"
            >
              Practice’s Notice of Privacy Practices
            </Link>
          )
          : "Practice’s Notice of Privacy Practices"
        }
        {" "}
        or in an authorization that you give to Practice (through the Platform
        or otherwise), Practice will not use or disclose any such information
        for any purpose other than to provide the Platform services and for
        Practice’s proper business management and administration. The foregoing
        notwithstanding, Practice may compile (or permit its licensors to
        compile) statistical data relating to your use of the Platform so long
        as such statistical data does not identify you. You hereby assign to
        Practice any and all of your right, title, and interest in and to such
        statistical data.
      </span>
    </p>

    <p>
      <span>
        You acknowledge that the Platform constitutes valuable assets and trade
        secrets of Practice and/or its licensors and that the Platform is
        protected by United States copyright law and international treaty
        provisions. You may not copy, modify, adapt, translate into any
        language, distribute, or create derivative works based on the Platform.
        {" "}
      </span>
    </p>

    <p>
      <span>
        You hereby authorize Practice, its affiliates, and their respective
        employees, agents, and contractors to initiate electronic communications
        by e-mail, telephone (including to cellular phone numbers), and text
        messages to provide reminders of upcoming events, appointments, or other
        purposes related to the Platform and the services provided to you by
        Practice, and you consent to such communications. Note that your
        carrier may charge for these incoming calls or messages.
      </span>
    </p>

    <p>
      <span>
        You may not do any of the following: (i) rent, lease, resell, assign, or
        otherwise transfer rights or access to the Platform; (ii) distribute,
        copy, reproduce, display, republish, or transmit any portion of the
        Platform; (iii) modify, translate, reverse engineer, decompile,
        disassemble, or create derivative works based upon the Platform or any
        software or programming related thereto; (iv)&nbsp;“frame” or “mirror”
        any portion of the Platform; (v) use any robot, spider, other automatic
        device, or manual process, to “screen scrape,” monitor, “mine,” or copy
        any portion of the Platform; use any device, software, methodology, or
        routine to interfere with the proper working of the Platform or servers
        or networks connected to the Platform; (vi) access data of which you are
        not an intended recipient or log into a server or account on a network
        related to the Platform that you are not expressly authorized to access;
        (vii) harvest or collect information about other Platform users; (viii)
        restrict or inhibit any other person from using the Platform, including
        by means of “hacking” or defacing any portion of the Platform; or (ix)
        attempt to do, or assist anyone else with doing or attempting, any of
        the foregoing.
      </span>
    </p>

    <p>
      <span>
        Laws regarding the transmission of personal information may vary from
        one state to another in the United States and from one country to
        another. You agree that you will use the Platform only in a manner that
        complies with all applicable laws in the jurisdictions in which you use
        the Platform and that your use of the Platform is subject to all
        applicable local, state, national, and international laws and
        regulations. You further agree to access the Platform only from within
        the United States.
      </span>
    </p>

    <p>
      <span>
        THE PLATFORM AND ALL SERVICES PROVIDED OR TO BE PROVIDED UNDER THIS
        AGREEMENT ARE PROVIDED “AS IS,” AND YOU ASSUME THE ENTIRE RISK AS TO THE
        QUALITY AND PERFORMANCE OF THE PLATFORM. PRACTICE, FOR ITSELF AND ITS
        LICENSORS, DISCLAIMS, ANY AND ALL WARRANTIES, CONDITIONS, OR
        REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL OR WRITTEN) WITH RESPECT TO
        THE PLATFORM OR ANY PART OF IT, INCLUDING ANY AND ALL IMPLIED WARRANTIES
        OR CONDITIONS OF TITLE, NONINFRINGEMENT, MERCHANTABILITY, OR FITNESS OR
        SUITABILITY FOR ANY PURPOSE (WHETHER OR NOT PRACTICE KNOWS, HAS REASON
        TO KNOW, HAS BEEN ADVISED, OR OTHERWISE IS IN FACT AWARE OF ANY SUCH
        PURPOSE), WHETHER ALLEGED TO ARISE BY LAW, BY REASON OF CUSTOM OR USAGE
        IN THE TRADE, BY COURSE OF DEALING, OR OTHERWISE.
      </span>
    </p>

    <p>
      <span>
        NEITHER PRACTICE NOR ITS LICENSORS SHALL BE LIABLE TO YOU (NOR TO ANY
        PERSON CLAIMING RIGHTS DERIVED FROM THE YOUR RIGHTS) FOR INCIDENTAL,
        INDIRECT, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES OF ANY
        KIND (INCLUDING LOST PROFITS, LOSS OF OR DAMAGE TO DATA, LOSS OF
        BUSINESS, OR OTHER ECONOMIC DAMAGE), WHETHER ARISING IN CONTRACT, TORT
        (INCLUDING NEGLIGENCE) OR OTHERWISE, AND REGARDLESS OF WHETHER PRACTICE
        WAS ADVISED, HAD OTHER REASON TO KNOW, SHOULD HAVE ANTICIPATED, OR IN
        FACT KNEW OF THE POSSIBILITY THEREOF. THE PLATFORM IS PROVIDED TO YOU
        FREE OF CHARGE, AND IN NO EVENT SHALL PRACTICE’S OR ITS LICENSORS’
        AGGREGATE LIABILITY TO YOU (INCLUDING LIABILITY TO ANY PERSON OR PERSONS
        WHOSE CLAIM OR CLAIMS ARE BASED ON OR DERIVED FROM A RIGHT OR RIGHTS
        CLAIMED BY OR THROUGH YOU), WITH RESPECT TO ANY AND ALL CLAIMS AT ANY
        AND ALL TIMES ARISING FROM OR RELATED TO THE SUBJECT MATTER OF THIS
        AGREEMENT (INCLUDING WITHOUT LIMITATION THE PLATFORM AND SERVICES), IN
        CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED
        $50. THE PROVISIONS OF THIS PARAGRAPH ARE INDEPENDENT OF, SEVERABLE
        FROM, AND TO BE ENFORCED INDEPENDENTLY OF ANY OTHER ENFORCEABLE OR
        UNENFORCEABLE PROVISION OF THIS AGREEMENT.
      </span>
    </p>

    <p>
      <span>
        Some jurisdictions do not allow the exclusion of certain warranties or
        the limitation or exclusion of liability for incidental or consequential
        damages, so some of the limitations and disclaimers above may not apply
        to you. To the extent applicable law does not permit such disclaimer of
        warranty or limitation of liability, the scope and duration of such
        warranty and the extent of such liability shall be the minimum permitted
        under such applicable law.
      </span>
    </p>

    <p>
      <span>
        You agree to indemnify and hold harmless Practice, its licensors, and
        their respective officers, directors, employees, and agents for any
        violation by you of the terms of this agreement.
      </span>
    </p>

    <p>
      <span>
        YOU ACKNOWLEDGE THAT THE DISCLAIMERS, LIMITATIONS OF LIABILITY, AND
        INDEMNIFICATION PROVISIONS IN THIS AGREEMENT ARE A MATERIAL INDUCEMENT
        AND CONSIDERATION FOR THE GRANT OF THE LICENSE CONTAINED IN THIS
        AGREEMENT AND FOR PROVIDING YOU WITH ACCESS TO THE PLATFORM.
      </span>
    </p>

    <p>
      <span>
        This agreement will be binding upon and inure to the benefit of the
        parties and their successors and assigns. Except as otherwise expressly
        provided herein, this agreement constitutes the entire agreement between
        the parties concerning the subject matter hereof. No prior or
        contemporaneous representations, inducements, promises, or agreements,
        oral or otherwise, between the parties with reference thereto will be of
        any force or effect. The failure of either party at any time to require
        performance by the other party of any provision of this agreement shall
        in no way affect the right of such party to require performance of that
        provision. Any waiver by either party of any breach of this agreement
        shall not be construed as a waiver of any continuing or succeeding
        breach of such provision, a waiver of the provision itself, or a waiver
        of any right under this agreement. If any provision of this agreement
        is ruled wholly or partly invalid or unenforceable by a court or other
        body of competent jurisdiction, the validity and enforceability of all
        provisions of this agreement not ruled to be invalid or unenforceable
        will be unaffected and the provision held wholly or partly invalid or
        unenforceable shall be deemed amended, and the court or other body is
        authorized to reform the provision, to the minimum extent necessary to
        render them valid and enforceable in conformity with the parties’ intent
        as manifested herein.
      </span>
    </p>

    <p>
      <span>
        This Agreement shall be construed and enforced in accordance with the
        laws of the state in which the Practice you are visiting is located
        (other than its conflicts of law provisions) and venue shall be
        exclusively in the federal or state courts sitting in such state.
      </span>
    </p>

    <p>
      <span>
        You may not assign this agreement or any of the rights or licenses
        granted under this agreement. Any attempted sublicense, transfer, or
        assignment in violation of this agreement is void.
      </span>
    </p>

    <p>
      <span>
        Practice’s licensor that provides the Platform to Practice so that
        Practice may make it available to you, Epion Health, Inc., is intended
        to be, and shall be, a third-party beneficiary of this agreement with
        rights to enforce this agreement against you.
      </span>
    </p>
  </>
);

export default TermsOfUsePractice;
