import PropTypes from "prop-types";
import Page from "components/Page";
import initTranslationsHelper from "utils/initTranslationsHelper";

const translate = initTranslationsHelper("universal.error_screen");

const ErrorScreen = ({ title, description, icon = "", children, actionText, onActionClick }) => (
  <Page>
    <Page.Title>{title}</Page.Title>
    <Page.Subtitle>{description}</Page.Subtitle>
    <Page.Icon>{icon}</Page.Icon>

    <Page.Content>{children}</Page.Content>

    {onActionClick && <Page.ButtonPrimary onClick={onActionClick}>{actionText}</Page.ButtonPrimary>}
  </Page>
);

ErrorScreen.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node,
  icon: PropTypes.string,
  children: PropTypes.node,
  actionText: PropTypes.string,
  onActionClick: PropTypes.func,
};

ErrorScreen.defaultProps = {
  description: "",
  icon: "",
  children: undefined,
  actionText: translate("default_action_text"),
  onActionClick: undefined,
};

export default ErrorScreen;
