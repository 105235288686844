import { Box, Link, Typography } from "@mui/material";
import Title from "components/Title";
import Modal from "components/Modal";
import Table, { TableHead, TableBody, TableRow, TableHeadCell, TableCell, TableFooter } from "components/Table";
import Bold from "components/Typography/Bold";
import formatCurrency from "utils/formatCurrency";
import toUSDateFormat from "utils/toUSDateFormat";
import translate from "./translate";

const ClaimsModal = ({
  onDoneButtonClick,
  cleanClaimsTitle,
  cleanClaimsSubtitle,
  uncleanClaimsTitle,
  uncleanClaimsSubtitle,
  cleanClaims,
  uncleanClaims,
  onDetailsClick,
  haveDescriptions,
}) => (
  <Modal
    disableBackdropClick
    disableGutters
    doneButtonText={translate("explanation_done")}
    onDoneButtonClick={onDoneButtonClick}
    open
    wide
  >
    <Title pb={0}>{cleanClaimsTitle}</Title>
    <Box pb={2} px={2}>
      <Typography variant="subtitle2">
        <Bold>{cleanClaimsSubtitle}</Bold>
      </Typography>
    </Box>
    <ClaimsTable claims={cleanClaims} haveDescriptions={haveDescriptions} onDetailsClick={onDetailsClick} showTotal />
    {uncleanClaims.length > 0 && (
      <>
        <Title pb={0} pt={4}>
          {uncleanClaimsTitle}
        </Title>
        <Box pb={2} px={2}>
          <Typography variant="subtitle2">
            <Bold>{uncleanClaimsSubtitle}</Bold>
          </Typography>
        </Box>
        <ClaimsTable claims={uncleanClaims} haveDescriptions={haveDescriptions} onDetailsClick={onDetailsClick} />
      </>
    )}
  </Modal>
);

const ClaimsTable = ({ claims, haveDescriptions, onDetailsClick, showTotal }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableHeadCell>{translate("date_column")}</TableHeadCell>
        {haveDescriptions && <TableHeadCell>{translate("item_column")}</TableHeadCell>}
        <TableHeadCell align="right">{translate("cost_column")}</TableHeadCell>
        {!haveDescriptions && <TableHeadCell />}
      </TableRow>
    </TableHead>
    <TableBody>
      {claims.map((claim) => (
        <TableRow key={claim.id}>
          <TableCell>{toUSDateFormat(claim.date)}</TableCell>
          {haveDescriptions && <TableCell>{claim.description}</TableCell>}
          <TableCell align="right">{formatCurrency(claim.balance)}</TableCell>
          {!haveDescriptions && (
            <TableCell>
              <Link component="button" onClick={() => onDetailsClick(claim)} variant="body1">
                {translate("see_details")}
              </Link>
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
    {showTotal && (
      <TableFooter>
        <TableRow>
          <TableCell>
            <strong>{translate("total")}</strong>
          </TableCell>
          {haveDescriptions && <TableCell />}
          <TableCell align="right">
            <strong>{formatCurrency(claims.reduce((sum, claim) => sum + claim.balance, 0))}</strong>
          </TableCell>
          {!haveDescriptions && <TableCell />}
        </TableRow>
      </TableFooter>
    )}
  </Table>
);

export default ClaimsModal;
