import initTranslationsHelper from "utils/initTranslationsHelper";

const initTranslateOptions = (scope) => initTranslationsHelper(`options.${scope}`);

const translateEthnicity = initTranslateOptions("ethnicities");
const translateLanguage = initTranslateOptions("languages");
const translateMaritalStatus = initTranslateOptions("marital_statuses");
const translateOccupation = initTranslateOptions("occupations");
const translatePatientRelationship = initTranslateOptions("patient_relationship_mappings");
const translatePreferredContactMethod = initTranslateOptions("contact_preferences");
const translateRace = initTranslateOptions("races");
const translateRelationship = initTranslateOptions("contact_relationship_mappings");

export const translateOption = (translateFn) => (option) => {
  let { label, value, key } = option;
  const { groupName, groupKey } = option;

  if (Array.isArray(option)) {
    [label, value, key] = option;
  }

  return {
    label: key ? translateFn(key) : label,
    value,
    groupName: groupKey ? translateFn(groupKey) : groupName,
  };
};

export const getOptionsTranslator = (translateFn) => (options) =>
  (options || []).map((option) => translateOption(translateFn)(option));

const translateEthnicityOption = translateOption(translateEthnicity);
const translateEthnicityOptions = getOptionsTranslator(translateEthnicity);

const translateLanguageOption = translateOption(translateLanguage);
const translateLanguageOptions = getOptionsTranslator(translateLanguage);

const translateMaritalStatusOption = translateOption(translateMaritalStatus);
const translateMaritalStatusOptions = getOptionsTranslator(translateMaritalStatus);

const translateOccupationOption = translateOption(translateOccupation);
const translateOccupationOptions = getOptionsTranslator(translateOccupation);

const translatePatientRelationshipOption = translateOption(translatePatientRelationship);
const translatePatientRelationshipOptions = getOptionsTranslator(translatePatientRelationship);

const translatePreferredContactMethodOption = translateOption(translatePreferredContactMethod);
const translatePreferredContactMethodOptions = getOptionsTranslator(translatePreferredContactMethod);

const translateRaceOption = translateOption(translateRace);
const translateRaceOptions = getOptionsTranslator(translateRace);

const translateRelationshipOption = translateOption(translateRelationship);
const translateRelationshipOptions = getOptionsTranslator(translateRelationship);

export {
  translateEthnicity,
  translateEthnicityOption,
  translateEthnicityOptions,
  translateLanguage,
  translateLanguageOption,
  translateLanguageOptions,
  translateMaritalStatus,
  translateMaritalStatusOption,
  translateMaritalStatusOptions,
  translateOccupation,
  translateOccupationOption,
  translateOccupationOptions,
  translatePatientRelationship,
  translatePatientRelationshipOption,
  translatePatientRelationshipOptions,
  translatePreferredContactMethod,
  translatePreferredContactMethodOption,
  translatePreferredContactMethodOptions,
  translateRace,
  translateRaceOption,
  translateRaceOptions,
  translateRelationship,
  translateRelationshipOption,
  translateRelationshipOptions,
};
