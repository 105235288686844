import RawHTML from "components/RawHTML";

import styles from "./styles.module.scss";

const NoticeOfPrivacyPractices = ({ practicePrivacyNoticeHTML }) => (
  <div className={styles.modalContainer}>
    <RawHTML>{practicePrivacyNoticeHTML}</RawHTML>
  </div>
);

export default NoticeOfPrivacyPractices;
