import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const SPACING = {
  xs: 2,
  sm: 3,
  md: 3,
  lg: 4,
  xl: 4,
};

function useResponsiveSpacing() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  const breakpoint =
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs";

  return SPACING[breakpoint] || 0;
}

export default useResponsiveSpacing;
