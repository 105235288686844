import * as Yup from "yup";
import getEnv from "utils/getEnv";
import initTranslationsHelper from "utils/initTranslationsHelper";

const translate = initTranslationsHelper("shared.validation");

const getPaymentAddressFormValidationSchema = () =>
  Yup.object().shape({
    addressLine1: addressValidation(() => Yup.string().trim().required()),
    addressLine2: addressValidation(() => Yup.string()),
    addressCity: addressValidation(() => Yup.string().trim().required()),
    addressState: addressValidation(() => Yup.string().trim().required()),
    addressZip: addressValidation(() =>
      Yup.string().matches(getEnv("regexes", {}).zip_code_regex, translate("error_invalid")).required(),
    ),
    useFromFile: Yup.string().required(),
  });

const addressValidation = (onConditionMet) =>
  Yup.string().when("useFromFile", {
    is: (useFromFile) => useFromFile === "false",
    then: onConditionMet(),
  });

export default getPaymentAddressFormValidationSchema;
