/**
***************************************************************
***************************************************************
** IF YOU MODIFY THE CONTENT OF THIS DOCUMENT, PLEASE UPDATE **
** THE VERSION NUMBER FOR `practice_patient_terms_of_use` in **
** app/models/agreement.rb **
***************************************************************
***************************************************************
*/
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const TermsOfUsePracticeEs = ({
  termsOfUseVersion,
  goToPageTwo,
  practiceTermsStandaloneUrl,
  forCheckIn,
}) => (
  <>
    {
      practiceTermsStandaloneUrl && (
        <p>
          Este acuerdo también puede ser visto, impreso y descargado en:
          {" "}
          {
            forCheckIn
            ? practiceTermsStandaloneUrl
            : (
              <Link href={practiceTermsStandaloneUrl} rel="noreferrer" target="_blank">
                {practiceTermsStandaloneUrl}
              </Link>
            )
          }
        </p>
      )
    }
    <Box component="header" mb={4} mt={2}>
      <Typography component="h1" variant="h5">CONDICIONES DE USO</Typography>
      <Typography component="h2" variant="h5">{termsOfUseVersion}</Typography>
    </Box>
    <p>
      <b>
        <i>
          <span>
            Este es un acuerdo vinculante. Lea con atención estos términos y
            condiciones de uso. Al utilizar esta plataforma de registro, usted
            reconoce la obligatoriedad de las disposiciones de este acuerdo. Si
            usted está en desacuerdo con estos términos y condiciones de uso,
            no utilice esta plataforma de registro. Podrá, en su lugar,
            registrar su cita en la oficina, en la recepción.
          </span>
        </i>
      </b>
    </p>

    <p>
      <span>
        Este acuerdo establece los términos y condiciones de uso de la
        aplicación de software disponible mediante esta tableta o sitio web (la
        “
        <b>Plataforma</b>
        ”) que le ha proporcionado el grupo proveedor de servicios de salud con
        el que usted tiene una cita para la que desea registrarse o con el
        que desea mantener una sesión de telesalud (“
        <b>Práctica</b>
        ”). Lea con atención estos términos y condiciones de uso.
      </span>
    </p>

    <p>
      <span>
        La Plataforma no tiene la finalidad de reemplazar la consulta médica,
        el diagnóstico ni el tratamiento, y es posible que la información
        existente en la Plataforma no sea precisa o no esté completa. Consulte
        siempre con un médico u otro profesional de la salud calificado todas
        sus inquietudes relacionadas con una afección de salud. Nunca ignore el
        consejo de los profesionales de la salud ni se demore en visitar al
        médico por alguna información disponible en la Plataforma.
        <b>
          <u>
            Si tiene una emergencia médica, llame al 911 o, si se encuentra en
            la Clínica, diríjase de inmediato a la recepción.
          </u>
        </b>
      </span>
    </p>

    <p>
      <span>
        Usted declara que la información proporcionada en el proceso de
        registro (el “
        <b>Registro</b>
        ”) es completa y verdadera. La información de Registro forma parte
        integrante de este acuerdo.
      </span>
    </p>

    <p>
      <span>
        Por el presente, la Clínica le confiere una licencia personal, no
        exclusiva, intransferible, revocable y no sublicenciable para el uso de
        la Plataforma, únicamente en los términos establecidos en este acuerdo.
        La Clínica se reserva, para sí y para sus licenciatarios, todos los
        derechos no conferidos expresamente en virtud de este acuerdo.
      </span>
    </p>

    <p>
      <span>
        Usted reconoce que el uso de la Plataforma se le confiere sin cargo y
        que la Clínica puede poner fin a este acuerdo y a la licencia otorgada
        en su marco, en cualquier momento, sin necesidad de notificación
        previa, mediante la desactivación de su acceso a la Plataforma. Usted
        puede dar por concluido este acuerdo en cualquier momento, mediante
        notificación escrita dirigida a la Clínica.
      </span>
    </p>

    <p>
      <span>
        Usted reconoce y acepta que ni este acuerdo ni el uso de la Plataforma
        implican la adquisición de derecho de propiedad alguno sobre la
        Plataforma. La Clínica y sus licenciatarios conservan la propiedad
        exclusiva, válida e incuestionable de la Plataforma y de todos los
        derechos de propiedad intelectual y demás derechos propietarios sobre
        esta. Usted reconoce que puede sugerir mejoras o comunicar a la Clínica
        ideas, invenciones, descubrimientos o conceptos (“
        <b>Ideas</b>
        ”) relacionados con la Plataforma, y usted y la Clínica pueden
        descubrir o crear en conjunto Ideas; usted acepta que tales Ideas serán
        en todo momento de propiedad de la Clínica o de sus licenciatarios y
        que podrán ser utilizadas, vendidas o transferidas por la Clínica o por
        sus licenciatarios a terceros, publicadas o puestas a disposición del
        público en general, a exclusivo criterio de la Clínica o de sus
        licenciatarios, sin necesidad de notificación, atribución de derechos,
        obligaciones de pago de regalías ni responsabilidades de ninguna clase
        hacia usted. Por el presente, usted cede a la Clínica sus derechos e
        intereses legítimos sobre tales Ideas.
      </span>
    </p>

    <p>
      <span>
        A efectos de la provisión de la Plataforma, usted confiere a la Clínica
        un derecho no exclusivo y una licencia para utilizar, copiar,
        distribuir, exhibir y transmitir la información que usted ingresa en la
        Plataforma y que la Plataforma obtiene de usted a partir del sistema de
        registros electrónicos de la Clínica, solo en la medida necesaria para
        prestar los servicios de la Plataforma. La Plataforma almacena cierta
        información de identificación personal sobre usted y su salud, y
        facilita la comunicación entre usted y su proveedor de servicios de
        salud. A menos que la ley o una orden judicial establezcan lo
        contrario, o según se indica en el
        {" "}
        {
          goToPageTwo
          ? (
            <Link
              className="agreementButton"
              component="button"
              onClick={() => goToPageTwo("noticeOfPrivacyPractices")}
              style={forCheckIn ? { color: "var(--primary-color)" } : null}
              type="button"
              variant="body2"
            >
              Aviso de la Clínica sobre prácticas de privacidad
            </Link>
          )
          : "Aviso de la Clínica sobre prácticas de privacidad"
        }
        {" "}
        o en una autorización que usted confiere a la Clínica (por medio de la
        Plataforma o no), la Clínica no utilizará ni divulgará esta información
        para ningún otro propósito que no sea el de prestar los servicios de la
        Plataforma y para la correcta administración y gestión de las
        actividades comerciales de la Clínica. Sin perjuicio de lo anterior, la
        Clínica puede recopilar (o permitir que sus licenciatarios recopilen)
        datos estadísticos relacionados con el uso de la Plataforma, en la
        medida en que estos datos no sean aptos para identificarlo. Por el
        presente, usted cede a la Clínica sus derechos e intereses legítimos
        sobre tales datos estadísticos.
      </span>
    </p>

    <p>
      <span>
        Usted acepta y reconoce que la Plataforma constituye un activo
        económicamente valioso y un secreto comercial de la Clínica o de sus
        licenciatarios, y que está protegida por las leyes en materia de
        propiedad intelectual de los Estados Unidos y por las normas de
        tratados internacionales. Usted no puede copiar, modificar, adaptar,
        traducir a ningún idioma, distribuir ni crear trabajos derivados a
        partir de la Plataforma.
        {" "}
      </span>
    </p>

    <p>
      <span>
        Usted autoriza a la Clínica, sus afiliados y sus respectivos empleados,
        representantes y contratistas a entablar comunicaciones por correo
        electrónico, por teléfono (incluso a teléfonos móviles) y por mensaje
        de texto para enviar recordatorios de eventos próximos, citas o para
        otros fines relacionados con la Plataforma y con los servicios que le
        presta la Clínica; usted presta su consentimiento para tales
        comunicaciones. Tenga presente que su proveedor de servicios de
        comunicaciones puede establecer cargos para estas llamadas o mensajes
        entrantes.
      </span>
    </p>

    <p>
      <span>
        Usted no puede realizar ninguno de los siguientes actos: (i) alquilar,
        rentar, revender, ceder o transferir por cualquier medio los derechos o
        el acceso a la Plataforma; (ii) distribuir, copiar, reproducir,
        exhibir, republicar ni transmitir parte alguna de la Plataforma; (iii)
        modificar, traducir, aplicar ingeniería inversa, descompilar, desarmar
        o crear trabajos derivados basados en la Plataforma o en cualquier
        software o programación relacionado; (iv) aplicar tecnologías de
        “frame” o “mirror” a parte alguna de la Plataforma; (v) utilizar
        robots, spiders u otros dispositivos automáticos o procesos manuales
        para hacer ”screen scraping”, monitoreo, extracción o copiado de parte
        alguna de la Plataforma; utilizar dispositivos, software, metodologías
        o rutinas para interferir con el adecuado funcionamiento de la
        Plataforma o de los servidores o redes conectados a la Plataforma; (vi)
        acceder a datos respecto de los cuales usted no sea el destinatario o
        iniciar sesión en un servidor o cuenta en una red relacionada con la
        Plataforma para la que no cuente con autorización expresa de acceso,
        (vii) cosechar o recolectar información sobre otros usuarios de la
        Plataforma, (viii) limitar o restringir el acceso de otras personas a
        la Plataforma, mediante hackeo o alteración de cualquier parte de la
        Plataforma; (ix) intentar o asistir a otros en el intento o la
        ejecución de alguno de los actos anteriores.
      </span>
    </p>

    <p>
      <span>
        La legislación en materia de transmisión de información personal puede
        variar entre los distintos estados que conforman los Estados Unidos y
        de un país a otro. Usted acepta utilizar la Plataforma únicamente de
        modo compatible con las leyes aplicables en las jurisdicciones en las
        que utilice la Plataforma y acepta que el uso de la Plataforma está
        sujeto a todas las leyes y reglamentos locales, estatales, nacionales e
        internacionales aplicables. Usted se compromete, además, a acceder a la
        Plataforma únicamente desde los Estados Unidos.
      </span>
    </p>

    <p>
      <span>
        LA PLATAFORMA Y LOS SERVICIOS PRESTADOS O A PRESTARSE EN EL MARCO DE
        ESTE ACUERDO SE OFRECEN “TAL COMO ESTÁN” Y USTED ASUME LA TOTALIDAD DE
        LOS RIESGOS EN LO REFERENTE A LA CALIDAD Y EL FUNCIONAMIENTO DE LA
        PLATAFORMA. LA CLÍNICA, EN NOMBRE PROPIO Y EN EL DE SUS LICENCIATARIOS,
        NO OFRECE GARANTÍAS NI FORMULA DECLARACIONES (EXPRESAS O IMPLÍCITAS,
        VERBALES O ESCRITAS) ACERCA DE LA PLATAFORMA O CUALQUIERA DE SUS
        PARTES, INCLUIDAS, ENTRE OTRAS LAS GARANTÍAS IMPLÍCITAS O CONDICIONES
        DE PROPIEDAD, NO INFRACCIÓN, COMERCIABILIDAD O ADECUACIÓN PARA UN FIN
        DETERMINADO (CON INDEPENDENCIA DE SI LA CLÍNICA SABE, TIENE MOTIVOS
        PARA SABER, HA SIDO INFORMADA O ESTÁ EN CONOCIMIENTO DE TAL FIN), SEA
        DERIVADO DE LA LEY, DE LOS USOS Y COSTUMBRES DEL COMERCIO, DE LAS
        RELACIONES COMERCIALES U OTRO.
      </span>
    </p>

    <p>
      <span>
        NI LA CLÍNICA NI SUS LICENCIATARIOS ESTÁN OBLIGADOS A RESPONDER ANTE
        USTED (NI ANTE PERSONAS QUE RECLAMEN DERECHOS DERIVADOS DE SUS
        DERECHOS) POR CAUSA DE DAÑOS INCIDENTALES, INDIRECTOS, CONSECUENTES,
        ESPECIALES, PUNITIVOS O EJEMPLARES DE NINGÚN TIPO (INCLUIDO LUCRO
        CESANTE, PÉRDIDA O DAÑOS A LA INFORMACIÓN, PÉRDIDA COMERCIAL U OTROS
        DAÑOS ECONÓMICOS), YA SEA QUE SURJAN POR CONTRATO, AGRAVIO (INCLUIDA LA
        NEGLIGENCIA) O DE OTRO MODO, E INDEPENDIENTEMENTE DE SI LA CLÍNICA HAYA
        SIDO ADVERTIDA, HAYA TENIDO MOTIVOS PARA SABER, DEBERÍA HABER
        ANTICIPADO O HAYA CONOCIDO DE HECHO LA POSIBILIDAD DE SU EXISTENCIA. LA
        PLATAFORMA SE OFRECE SIN CARGO Y, EN NINGUNA CIRCUNSTANCIA, LA
        RESPONSABILIDAD TOTAL DE LA CLÍNICA O DE SUS LICENCIATARIOS HACIA USTED
        (INCLUIDA LA RESPONSABILIDAD RESPECTO DE PERSONAS CUYAS RECLAMACIONES
        SE FUNDEN EN SUS DERECHOS) CON RESPECTO A TODAS LAS RECLAMACIONES QUE
        PUEDAN DERIVARSE, EN CUALQUIER MOMENTO, DEL OBJETO DEL PRESENTE ACUERDO
        O QUE ESTÉN RELACIONADAS CON EL PRESENTE ACUERDO (INCLUIDOS, ENTRE
        OTROS, LA PLATAFORMA Y LOS SERVICIOS), YA SEA POR CONTRATO, GARANTÍA O
        AGRAVIO (INCLUIDA LA NEGLIGENCIA), O DE OTRO MODO; PODRÁ SUPERAR LA
        SUMA DE CINCUENTA DÓLARES ESTADOUNIDENSES (50 USD). LAS DISPOSICIONES
        DE ESTE APARTADO SON INDEPENDIENTES Y DIVISIBLES, EJECUTABLES CON
        INDEPENDENCIA DE TODA OTRA DISPOSICIÓN EXIGIBLE O INEXIGIBLE DE ESTE
        ACUERDO.
      </span>
    </p>

    <p>
      <span>
        Ciertas jurisdicciones no admiten la exclusión de ciertas garantías o
        la limitación o exclusión de la responsabilidad ante daños incidentales
        o consecuentes, por lo que algunas de las limitaciones y exenciones de
        responsabilidad antes mencionadas pueden no resultarle aplicables. En
        la medida en que la legislación aplicable no admita dicha renuncia de
        garantía o limitación de responsabilidad, el alcance y la duración de
        tales garantías y el alcance de dicha responsabilidad serán los mínimos
        permitidos por la ley aplicable.
      </span>
    </p>

    <p>
      <span>
        Usted acepta indemnizar y eximir de responsabilidad a la Clínica, sus
        licenciatarios y sus respectivos funcionarios, directores, empleados y
        representantes por cualquier infracción de su parte a las disposiciones
        de este acuerdo.
      </span>
    </p>

    <p>
      <span>
        USTED RECONOCE QUE LAS ESTIPULACIONES EN MATERIA DE EXCLUSIÓN,
        LIMITACIONES Y EXENCIÓN DE RESPONSABILIDAD TIENEN NATURALEZA SUSTANCIAL
        Y SON DECISIVAS PARA EL OTORGAMIENTO DE LA LICENCIA CONTENIDA EN ESTE
        ACUERDO Y PARA PROPORCIONARLE EL ACCESO A LA PLATAFORMA.
      </span>
    </p>

    <p>
      <span>
        El presente acuerdo es vinculante para las partes y redundará en su
        beneficio y el de sus sucesores y cesionarios. A menos que se
        establezca lo contrario expresamente, este acuerdo representa la
        totalidad del entendimiento entre las partes en lo concerniente a su
        objeto. Ninguna declaración, promesa, incentivo, acuerdo, sea escrito o
        verbal, anterior o simultáneo, con referencia al objeto del presente
        tendrá vigencia ni efecto. La falta de cualquiera de las partes de
        exigir, en cualquier momento, el cumplimiento de alguna de las
        disposiciones de este acuerdo de ningún modo afectará su derecho de
        exigir el cumplimiento de dicha disposición. La dispensa por una parte
        del incumplimiento de cualquiera de las disposiciones de este acuerdo
        no se entenderá en el sentido de que configura una dispensa de
        incumplimientos continuos o posteriores, una renuncia a la disposición
        en sí ni a ningún derecho conferido por este acuerdo. Si una de las
        disposiciones de este acuerdo fuera hallada inválida o inexigible por
        un tribunal u otro organismo competente, la validez y la exigibilidad
        de las restantes disposiciones no se verá afectada, y la disposición
        inválida o inexigible se considerará modificada, y el tribunal u
        organismo competente está autorizado a modificarla, en la medida mínima
        para restaurar su validez y exigibilidad, de conformidad con la
        intención de las partes manifestada en este acuerdo.
      </span>
    </p>

    <p>
      <span>
        Este acuerdo se regirá e interpretará de conformidad con las leyes del
        estado en el que se encuentre la Clínica que usted visite (sin
        perjuicio de las disposiciones en materia de conflicto de leyes) y la
        competencia territorial corresponde a los tribunales estatales o
        federales con asiento en dicho estado.
      </span>
    </p>

    <p>
      <span>
        Usted no puede ceder este acuerdo ni los derechos o licencias
        conferidos por este acuerdo. Todo intento de sublicenciamiento,
        transferencia o cesión en infracción a las disposiciones de este
        acuerdo es nulo.
      </span>
    </p>

    <p>
      <span>
        Epion Health, Inc., el licenciatario de la Clínica que presta los
        servicios de la Plataforma a la Clínica para que esta pueda ponerlos a
        su alcance, será considerado en todo momento un tercero beneficiario de
        este acuerdo, y está legitimado para exigirle a usted el cumplimiento
        de este acuerdo.
      </span>
    </p>
  </>
);

export default TermsOfUsePracticeEs;
