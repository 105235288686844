import { useRef, useState } from "react";
import PropTypes from "prop-types";
import RadioButtonGroup from "components/RadioButtonGroup";
import formatCurrency from "utils/formatCurrency";
import OtherAmountLabel from "./OtherAmountLabel";
import t from "./translate";

const PaymentAmountSelect = ({ isAmountToPayValid, max, min, onChange, value }) => {
  const otherAmountRef = useRef(null);
  const [showOtherAmountError, setShowOtherAmountError] = useState(false);
  const currentOptionValue = value === max ? OPTION_MAX : OPTION_OTHER;
  const showOtherAmountValue = currentOptionValue === OPTION_OTHER;

  const handleOptionChange = (e) => {
    const newcurrentOptionValue = e.target.value;

    if (newcurrentOptionValue === currentOptionValue) return;

    if (newcurrentOptionValue === OPTION_MAX) {
      setShowOtherAmountError(false);
      onChange(max);
    }

    if (newcurrentOptionValue === OPTION_OTHER) {
      otherAmountRef.current.focus();
    }
  };

  const handleOtherAmountInputFocus = () => {
    setShowOtherAmountError(false);

    if (currentOptionValue === OPTION_MAX) {
      onChange("");
    }
  };

  const handleOtherAmountInputBlur = () => {
    if (currentOptionValue === OPTION_OTHER && !isAmountToPayValid) {
      setShowOtherAmountError(true);
    }
  };

  const options = [
    {
      label: formatCurrency(max),
      value: OPTION_MAX,
    },
    {
      label: (
        <OtherAmountLabel
          error={showOtherAmountError && t("error_message")}
          inputRef={otherAmountRef}
          max={max}
          min={min}
          onBlur={handleOtherAmountInputBlur}
          onChange={onChange}
          onFocus={handleOtherAmountInputFocus}
          showValue={showOtherAmountValue}
          value={value}
        />
      ),
      value: OPTION_OTHER,
    },
  ];

  return (
    <RadioButtonGroup
      label={t("label")}
      name="payment-amount"
      onChange={handleOptionChange}
      options={options}
      required
      value={currentOptionValue}
    />
  );
};

PaymentAmountSelect.propTypes = {
  isAmountToPayValid: PropTypes.bool.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PaymentAmountSelect.defaultProps = {
  min: 0,
  onChange: () => {},
  value: 0,
};

export default PaymentAmountSelect;

const OPTION_MAX = "max";
const OPTION_OTHER = "other";
