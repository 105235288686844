import { useEffect, useLayoutEffect, useState } from "react";
import { loadVGSCollect } from "@vgs/collect-js";
import getEnv from "utils/getEnv";
import Log from "utils/logging";
import translate from "./translate";

const MAX_ERROR_RETRIES = 2;

const VGS_ERROR_MESSAGE_MAPPING = {
  "is not a valid card number": "errors.invalid_card_number",
  "is not a valid security code": "errors.invalid_security_code",
  "is required": "errors.required",
};

const fieldErrorText = (vgsErrorMessage) => {
  const errorMessageTranslationKey = VGS_ERROR_MESSAGE_MAPPING[vgsErrorMessage];
  return errorMessageTranslationKey ? translate(errorMessageTranslationKey) : vgsErrorMessage;
};

const createElement = (elementId, container) => {
  if (document.getElementById(elementId)) {
    document.getElementById(elementId).remove();
  }

  const element = document.createElement("div");
  element.id = elementId;

  element.classList.add("vgsField");
  const style = document.createElement("style");
  style.textContent = `
    .vgsField { box-sizing: border-box; padding: 2px; position: absolute; }
    .vgsField iframe { height: 100%; width: 100%; }
  `;
  document.body.appendChild(style);

  updateElementPosition(element, container);

  return document.body.appendChild(element);
};

const updateElementPosition = (element, container) => {
  if (!element || !container) {
    return;
  }

  const containerRect = container.getBoundingClientRect();

  element.style.width = `${containerRect.width}px`;
  element.style.height = `${containerRect.height}px`;
  element.style.top = `${containerRect.top + window.scrollY}px`;
  element.style.left = `${containerRect.left + window.scrollX}px`;
};

const updateVGSFieldsPosition = () => {
  updateElementPosition(document.getElementById("cc-name"), сardNameElementContainer());
  updateElementPosition(document.getElementById("cc-number"), сardNumberElementContainer());
  updateElementPosition(document.getElementById("cc-cvv"), cvvElementContainer());
  updateElementPosition(document.getElementById("cc-exp"), сardExpirationElementContainer());
};

const removeVGSFields = () => {
  document.getElementById("cc-name")?.remove();
  document.getElementById("cc-number")?.remove();
  document.getElementById("cc-cvv")?.remove();
  document.getElementById("cc-exp")?.remove();
};

const сardNameElementContainer = () =>
  document.getElementById("cc-name-container") ||
  document.querySelector("react-element")?.shadowRoot?.getElementById("cc-name-container");

const createCardNameElement = () => createElement("cc-name", сardNameElementContainer());

const сardNumberElementContainer = () =>
  document.getElementById("cc-number-container") ||
  document.querySelector("react-element")?.shadowRoot?.getElementById("cc-number-container");

const createCardNumberElement = () => createElement("cc-number", сardNumberElementContainer());

const cvvElementContainer = () =>
  document.getElementById("cc-cvv-container") ||
  document.querySelector("react-element")?.shadowRoot?.getElementById("cc-cvv-container");

const createCvvElement = () => createElement("cc-cvv", cvvElementContainer());

const сardExpirationElementContainer = () =>
  document.getElementById("cc-exp-container") ||
  document.querySelector("react-element")?.shadowRoot?.getElementById("cc-exp-container");

const createCardExpirationElement = () => createElement("cc-exp", сardExpirationElementContainer());

const usePaymentFormState = ({ cardHolderName, submitFormPath }) => {
  const [cardNameField, setCardNameField] = useState();
  const [cardNumberField, setCardNumberField] = useState();
  const [cardCVVField, setCardCVVField] = useState();
  const [cardExpirationField, setCardExpirationField] = useState();
  const [form, setForm] = useState({});
  const [error, setError] = useState("");
  const [formState, setFormState] = useState({
    card_name: { isEmpty: !cardHolderName },
    card_number: { isEmpty: true },
    card_cvv: { isEmpty: true },
    card_exp: { isEmpty: true },
  });
  const [errorRetryCount, setErrorRetryCount] = useState(0);
  let vgsForm = {};

  // VGS Collect initialization
  useEffect(() => {
    initVgsForm();
    window.addEventListener("resize", updateVGSFieldsPosition);

    return () => {
      window.removeEventListener("resize", updateVGSFieldsPosition);
      vgsForm.unmount();
      removeVGSFields();
    };
  }, []);

  useLayoutEffect(() => {
    updateVGSFieldsPosition();
  });

  const initVgsForm = () => {
    removeVGSFields();
    const vgsCollectToken = getEnv("vgs_collect_token");

    if (!vgsCollectToken) {
      console.error("VGS token is not set");
      return;
    }

    const vgsCredentials = {
      vaultId: vgsCollectToken,
      environment: getEnv("vgs_vault_environment"),
      version: getEnv("vgs_collect_version"),
    };

    loadVGSCollect(vgsCredentials)
      .then(initForm)
      .catch((e) => window.Rollbar.error(`VGS collect load failed: ${e.message}`));
  };

  const initForm = (vgsCollect) => {
    const vgsStyles = { boxSizing: "border-box", fontSize: "16px", paddingLeft: "12px", paddingRight: "12px" };
    vgsForm = vgsCollect.init(setFormState);
    setForm(vgsForm);

    const cardName =
      !!сardNameElementContainer() &&
      vgsForm.field(createCardNameElement(), {
        autoComplete: "cc-name",
        css: vgsStyles,
        defaultValue: cardHolderName,
        name: "transaction.billing_data.card_name",
        type: "text",
        validations: ["required"],
      });

    const cardNumber =
      !!сardNumberElementContainer() &&
      vgsForm.field(createCardNumberElement(), {
        autoComplete: "cc-number",
        css: { ...vgsStyles, paddingRight: "40px" },
        name: "transaction.billing_data.card_number",
        showCardIcon: true,
        type: "card-number",
        validations: ["required", "validCardNumber"],
      });

    const cardCVV =
      !!cvvElementContainer() &&
      vgsForm.field(createCvvElement(), {
        autoComplete: "cc-csc",
        css: vgsStyles,
        name: "transaction.billing_data.card_cvv",
        type: "card-security-code",
        validations: ["required", "validCardSecurityCode"],
      });

    const cardExpiration =
      !!сardExpirationElementContainer() &&
      vgsForm.field(createCardExpirationElement(), {
        autoComplete: "cc-exp",
        css: vgsStyles,
        name: "transaction.billing_data.card_exp",
        placeholder: "MM / YY",
        type: "card-expiration-date",
        validations: ["required", "validCardExpirationDate"],
        yearLength: "2",
      });

    if (cardNumber) {
      cardNumber.setCVCDependency(cardCVV);
      setCardNumberField(cardNumber);
    }

    setCardCVVField(cardCVV);
    setCardNameField(cardName);
    setCardExpirationField(cardExpiration);
  };

  // VGS Collect form submission
  const onSubmit = (
    paymentAmount,
    billingAddress,
    billingZip,
    additionalData = {},
    successCallback = () => {},
    recoverableCallback = () => {},
    unrecoverableCallback = () => {},
    finallyCallback = () => {},
  ) => {
    const cardNumberFieldState = formState["transaction.billing_data.card_number"];

    try {
      form.submit(
        submitFormPath,
        {
          data: (formValues) => ({
            transaction: {
              payment_amount: paymentAmount?.toString(),
              billing_data: {
                card_holder_name: formValues["transaction.billing_data.card_name"],
                card_expiration: formValues["transaction.billing_data.card_exp"],
                card_brand: cardNumberFieldState?.cardType,
                card_bin: cardNumberFieldState?.bin,
                card_cvv: formValues["transaction.billing_data.card_cvv"],
                card_last4: cardNumberFieldState?.last4,
                card_number: formValues["transaction.billing_data.card_number"],
                billing_address: billingAddress,
                billing_zip: billingZip,
              },
              ...additionalData,
            },
          }),
        },
        (status, response) => {
          switch (status) {
            case 200:
              setTimeout(() => {
                // add timeout to finish VGS callbacks
                successCallback(response);
              });
              break;
            case 303:
              console.info("transaction token invalid");
              window.location = response.url;
              break;
            case 422:
              if (response.error.recoverable && errorRetryCount < MAX_ERROR_RETRIES) {
                setErrorRetryCount(errorRetryCount + 1);
                setError(response.error.text);
                setTimeout(() => {
                  // add timeout to finish VGS callbacks
                  recoverableCallback(response);
                });
              } else {
                setTimeout(() => {
                  // add timeout to finish VGS callbacks
                  unrecoverableCallback(response);
                });
              }
              break;
            default:
              setTimeout(() => {
                // add timeout to finish VGS callbacks
                unrecoverableCallback(response);
              });
          }
          finallyCallback();
        },
      );
    } catch (e) {
      Log.error("VGS form submission failed", e);
      setTimeout(() => {
        // add timeout to finish VGS callbacks
        unrecoverableCallback({});
        finallyCallback();
      });
    }
  };

  const vgsFieldError = (field) => {
    const fieldState = formState[field];

    if (!fieldState?.isDirty) return "";

    const vgsErrorMessage = fieldState?.errorMessages?.[0];
    return fieldErrorText(vgsErrorMessage);
  };

  const vgsFieldValid = (field) => formState[field]?.isValid;
  const vgsFieldDirty = (field) => formState[field]?.isDirty;
  const vgsFieldEmpty = (field) => formState[field]?.isEmpty;
  const vgsFieldFocused = (field) => formState[field]?.isFocused;

  const focusCardNameField = () => cardNameField?.focus();
  const focusCardNumberField = () => cardNumberField?.focus();
  const focusCardCVVField = () => cardCVVField?.focus();
  const focusCardExpirationField = () => cardExpirationField?.focus();

  const submitDisabled = (props) =>
    !props.isValid ||
    (сardNameElementContainer() && !vgsFieldValid("transaction.billing_data.card_name")) ||
    (сardNumberElementContainer() && !vgsFieldValid("transaction.billing_data.card_number")) ||
    (cvvElementContainer() && !vgsFieldValid("transaction.billing_data.card_cvv")) ||
    (сardExpirationElementContainer() && !vgsFieldValid("transaction.billing_data.card_exp")) ||
    props.isSubmitting;

  return {
    vgsFieldError,
    vgsFieldValid,
    vgsFieldDirty,
    vgsFieldEmpty,
    vgsFieldFocused,
    focusCardExpirationField,
    focusCardNameField,
    focusCardNumberField,
    focusCardCVVField,
    error,
    initVgsForm,
    onSubmit,
    submitDisabled,
    updateVGSFieldsPosition,
  };
};

export default usePaymentFormState;
