import { useTheme } from "@mui/material/styles";
import ErrorScreen from "./index";

const UnrecoverableError = ({ ...props }) => {
  const theme = useTheme();

  return <ErrorScreen {...props} icon={theme.icons?.unrecoverableError || "⚠️"} />;
};

export default UnrecoverableError;
