import { useState } from "react";
import PropTypes from "prop-types";
import partition from "lodash/partition";
import some from "lodash/some";
import ModalCarousel from "components/Modal/ModalCarousel";
import ClaimsModal from "./ClaimsModal";
import DetailsModal from "./DetailsModal";

const OutstandingBalanceModal = ({
  claims,
  open,
  onDoneButtonClick,
  cleanClaimsSubtitle,
  cleanClaimsTitle,
  uncleanClaimsSubtitle,
  uncleanClaimsTitle,
}) => {
  const [cleanClaims, uncleanClaims] = partition(claims, { clean: true });
  const [currentItem, setCurrentItem] = useState(0);
  const [currentDetailsClaim, setCurrentDetailsClaim] = useState();

  const onDetailsClick = (claim) => {
    setCurrentDetailsClaim(claim);
    setCurrentItem(1);
  };

  const handleDoneButtonClick = () => {
    setCurrentItem(0);
    onDoneButtonClick();
  };

  return (
    open && (
      <ModalCarousel
        currentItem={currentItem}
        modals={[
          <ClaimsModal
            cleanClaims={cleanClaims}
            cleanClaimsSubtitle={cleanClaimsSubtitle}
            cleanClaimsTitle={cleanClaimsTitle}
            haveDescriptions={some(claims, (claim) => claim.description)}
            key="claims"
            onDetailsClick={onDetailsClick}
            onDoneButtonClick={handleDoneButtonClick}
            uncleanClaims={uncleanClaims}
            uncleanClaimsSubtitle={uncleanClaimsSubtitle}
            uncleanClaimsTitle={uncleanClaimsTitle}
          />,
          <DetailsModal
            charges={currentDetailsClaim?.charges}
            key="details"
            onCancelButtonClick={() => setCurrentItem(0)}
            onDoneButtonClick={handleDoneButtonClick}
            total={currentDetailsClaim?.charges.reduce((sum, charge) => sum + charge.balance, 0)}
          />,
        ]}
      />
    )
  );
};

OutstandingBalanceModal.propTypes = {
  claims: PropTypes.arrayOf(
    PropTypes.shape({
      balance: PropTypes.number.isRequired,
      charges: PropTypes.arrayOf(
        PropTypes.shape({
          balance: PropTypes.number.isRequired,
          date: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          id: PropTypes.number.isRequired,
        }),
      ).isRequired,
      clean: PropTypes.bool.isRequired,
      date: PropTypes.string.isRequired,
      description: PropTypes.string,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  cleanClaimsSubtitle: PropTypes.string,
  cleanClaimsTitle: PropTypes.string,
  onDoneButtonClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  uncleanClaimsSubtitle: PropTypes.string,
  uncleanClaimsTitle: PropTypes.string,
};

OutstandingBalanceModal.defaultProps = {
  cleanClaimsSubtitle: null,
  cleanClaimsTitle: "Unpaid Charges",
  uncleanClaimsSubtitle: null,
  uncleanClaimsTitle: "Paid/Scheduled Charges",
};

export default OutstandingBalanceModal;
