import clsx from "clsx";
import styles from "./styles.module.scss";

const Table = ({ children, className }) => <table className={clsx(styles.table, className)}>{children}</table>;

export const TableHead = ({ children, className }) => (
  <thead className={clsx(styles.rowContainer, styles.tableHead, className)}>{children}</thead>
);

export const TableBody = ({ children, className }) => (
  <tbody className={clsx(styles.rowContainer, styles.tableBody, className)}>{children}</tbody>
);

export const TableFooter = ({ children }) => (
  <tfoot className={clsx(styles.rowContainer, styles.tableFooter)}>{children}</tfoot>
);

export const TableRow = ({ children }) => <tr className={styles.tableRow}>{children}</tr>;

const BaseTableCell = ({ component: Component, className, align, ...props }) => (
  <Component className={clsx(styles.baseTableCell, styles[align], className)} {...props} />
);

export const TableHeadCell = ({ children, className, ...props }) => (
  <BaseTableCell align="left" {...props} className={clsx(styles.tableHeadCell, className)} component="th">
    {children}
  </BaseTableCell>
);

export const TableCell = ({ children, className, ...props }) => (
  <BaseTableCell align="left" {...props} className={clsx(styles.tableCell, className)} component="td">
    {children}
  </BaseTableCell>
);

export default Table;
